import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Loader = () => (
  <div className="loader-overlay">
    <div className="loader"></div>
  </div>
);

const AlertContactForm = () => {
  const [toggle, setToggle] = useState(true);
  const [loading, setLoading] = useState(false); // Loading state
  const [formData, setFormData] = useState({
    from_name: '',
    from_email: '',
    form_countryCode: '+91',
    form_phone: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    const messageData = {
      name: formData.from_name || null,
      email: formData.from_email || null,
      phone: formData.form_countryCode && formData.form_phone
          ? `${formData.form_countryCode} ${formData.form_phone}`
          : null,
      zone: formData.from_timezone || 'UTC', // Default to UTC if not provided
      time: formData.form_time || null,
      date: formData.form_date || new Date(), // Ensure `date` is populated with a valid value
      leadSource: 'website_alert_form', // Default lead source
      message: formData.message || '', // Default to empty string if not provided
      status: 'Booked', // Default status should match your schema
      remarks: 'Not yet contacted', // Default remarks
      bookedDate: new Date(), // Default to current date/time for bookedDate
      updatedBy: 'No update' // Default value if no updates have been made
    };

    try {
      const response = await axios.post('https://techblume-backendcrm.onrender.com/api/bookings/add', messageData);
      alert('Your details have been sent successfully!');
      console.log(response.data);
      
      // Reset form fields
      setFormData({
        from_name: '',
        from_email: '',
        form_countryCode: '+91',
        form_phone: ''
      });

      // Mark form as submitted in localStorage
      localStorage.setItem('formSubmitted', 'true');
      setToggle(false); // Hide form after submission
      document.body.style.overflow = 'auto'; // Re-enable scrolling

      window.location.reload();

    } catch (error) {
      console.error('Error sending message', error);
      alert('Sorry, there was an issue sending your message. Please try again.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    const formSubmitted = localStorage.getItem('formSubmitted');
    if (!formSubmitted) {
      setToggle(true); // Show the form if not submitted
    } else {
      setToggle(false); // Hide form if already submitted
    }
  }, []);

  return (
    <div>
      {loading && <Loader />} {/* Show loader when loading */}
      <div className={toggle ? "contact-form" : "contact-form active"}>
        <main>
          <h1>Send us a message!</h1>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="from_name"
                value={formData.from_name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="from_email"
                value={formData.from_email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">Phone</label>
              <div className="phone-input">
                <select
                  name="form_countryCode"
                  value={formData.form_countryCode}
                  onChange={handleChange}
                  required
                >
                  <option value="+91">+91 (India)</option>
                  <option value="+1">+1 (US)</option>
                  <option value="+44">+44 (UK)</option>
                </select>
                <input
                  type="tel"
                  name="form_phone"
                  value={formData.form_phone}
                  onChange={handleChange}
                  placeholder="Enter your phone number"
                  required
                />
              </div>
            </div>

            <button type="submit" className="submit-btn">
              Send
            </button>
          </form>
        </main>
      </div>
    </div>
  );
};

export default AlertContactForm;
