import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../assets/css/Bookings.css';
import Logo from '../assets/img/TechblumeLogo.png';
import axios from 'axios';
import Footer from './Footer';
import { Link } from 'react-router-dom';

const Loader = () => (
  <div className="loader-overlay">
    <div className="loader"></div>
  </div>
);

const Bookings = () => {
  const [formData, setFormData] = useState({
    from_name: '',
    from_email: '',
    form_countryCode: '+91',
    form_phone: '',
    from_timezone: '',
    form_time: '',
    form_date: '',
});
  
  const [loading, setLoading] = useState(false); // Loading state
  const form = useRef();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    const bookingData = {
      name: formData.from_name || null,
      email: formData.from_email || null,
      phone: formData.form_countryCode && formData.form_phone
          ? `${formData.form_countryCode} ${formData.form_phone}`
          : null,
      zone: formData.from_timezone || 'UTC', // Default to UTC if not provided
      time: formData.form_time || null,
      date: formData.form_date ? new Date(formData.form_date).setUTCHours(0, 0, 0, 0) : null, // Set time to 00:00:00 UTC
      leadSource: 'website_booking_form', // Default lead source
      message: '', // Default to empty string if not provided
      status: 'Booked', // Default status should match your schema
      remarks: 'Not yet contacted', // Default remarks
      bookedDate: new Date(), // Default to current date/time for bookedDate
      updatedBy: 'No update' // Default value if no updates have been made
    };
  

    try {
      const response = await axios.post('https://techblume-backendcrm.onrender.com/api/bookings/add', bookingData);
      alert('Your meeting slot with TechBlume has been successfully booked! If you have any questions, feel free to contact us at support@techblume.in or call +91 96864 30320.');
      console.log(response.data);

      // Reset form data
      setFormData({
        from_name: '',
        from_email: '',
        form_countryCode: '+91',
        form_phone: '',
        from_timezone: '',
        form_time: '',
        form_date: ''
      });

    } catch (error) {
      console.error('Error creating booking', error);
      alert("We're sorry! We couldn't book your slot at this time. Please reach out to us at support@techblume.in or call +91 96864 30320 to confirm your booking.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Techblume | Slot Booking for Meeting</title>
      </Helmet>

      {loading && <Loader />} {/* Show loader when loading */}

      <div className='Booking-page'>
        <div className='booking-section1'>
            <Link to='/'><img src={Logo} alt="logo" /></Link>
          <div>
            <h2>Reserve Your Slot Now!</h2>
            <p>Take the first step toward a successful tech career.</p>
          </div>
        </div>

        <div className="booking-section2">
          <form ref={form} onSubmit={handleSubmit}>
            <h1>Booking Form</h1>

            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="from_name"
                value={formData.from_name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="from_email"
                value={formData.from_email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">Phone</label>
              <div className="phone-input">
                <select
                  name="form_countryCode"
                  value={formData.form_countryCode}
                  onChange={handleChange}
                  required
                >
                  <option value="+91">+91 (India)</option>
                  <option value="+1">+1 (US)</option>
                  <option value="+44">+44 (UK)</option>
                </select>
                <input
                  type="tel"
                  id="phone"
                  name="form_phone"
                  value={formData.form_phone}
                  onChange={handleChange}
                  placeholder="Enter your phone number"
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="timezone">Time Zone</label>
              <select
                id="timeZone"
                name="from_timezone"
                value={formData.from_timezone}
                onChange={handleChange}
                required
              >
                <option value="">Select your time zone</option>
                <option value="Asia/Kolkata">Asia/Kolkata</option>
                <option value="US/Eastern">US/Eastern</option>
                <option value="US/Central">US/Central</option>
                <option value="US/Mountain">US/Mountain</option>
                <option value="US/Pacific">US/Pacific</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="time">Time</label>
              <input
                type="time"
                id="time"
                name="form_time"
                value={formData.form_time}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="date">Date</label>
              <input
                type="date"
                id="date"
                name="form_date"
                value={formData.form_date}
                onChange={handleChange}
                required
              />
            </div>

            <button type="submit" className="submit-btn">Confirm Booking</button>
          </form>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Bookings;
